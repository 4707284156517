import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  headingTextContainer: {
    width: '67%',
    margin: 'auto',
    padding: 20,
    paddingBottom: 0,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      margin: 0,
      width: '100%',
    },
  },
  videoContainer: {
    width: '100%',
    maxWidth: 700,
  },
  videoFrame: {
    width: '100%',
    height: 0,
    paddingBottom: '75%',
    position: 'relative',
  },
  video: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  joinLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 400,
  },
  text: {
    fontSize: '1.3rem',
    lineHeight: 1.5,
  },
  description: {
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '1.5rem',
    },
  },
  secondDescription: {
    marginBottom: 0,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      marginBottom: 20,
    },
  },
  listItem: {
    marginBottom: 0,
  },
  aboutSection: {
    '& img': {
      objectFit: 'cover',
    },
  },
}))
